<template>
  <div class="account-box">
    <div>
      <TextScroll :isVis="isVis" :dataList="textArr" @handlerNotice="getAnnouncement"></TextScroll>
    </div>
    <div class="box">
      <div>
        <el-alert :title="$t('Funds.Accounts.TipInfo')" type="info" show-icon>
        </el-alert>
      </div>
      <div
        v-if="account_data !== ''"
        style="text-align: center; margin-top: 1rem"
      >
        <!-- <CAlert color="info" variant="solid">{{ account_data.status }}</CAlert> -->
        <div
          class="status"
          :style="`background:${getStatus(account_data.status)}`"
        >
          {{ $t("Funds.Accounts.DStatus") }}{{ account_data.status }}
        </div>
      </div>
      <div>
        <div class="act-card">
          <el-card class="box-card">
            <div class="con-box">
              <div
                v-if="account_data !== ''"
                class="item-box"
                style="width: 420px; margin: 0 auto"
              >
                <el-row :gutter="20">
                  <el-col :span="10">
                    <div class="item-t" style="display:flex;align-items:center">
                      {{ $t("Funds.Accounts.AccountName") }}
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="$t('Funds.Accounts.AccountNameTips')"
                        placement="right-start"
                      >
                        <i class="el-icon-warning" style="color: #e6a23c;padding:0;margin-left:.3rem"></i>
                      </el-tooltip>
                      
                    </div>
                    <div class="item-t">
                      {{ $t("Funds.Accounts.AccountNumber") }}
                    </div>
                    <div class="item-t">
                      {{ $t("Funds.Accounts.BankName") }}
                    </div>
                    <div class="item-t">
                      {{ $t("Funds.Accounts.BankCode") }}
                    </div>
                    <div class="item-t">
                      {{ $t("Funds.Accounts.BranchCode") }}
                    </div>
                    <div class="item-t">SWIFT Code</div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item-v">
                      {{ account_data.account_name || "-" }}
                    </div>
                    <div class="item-v">
                      {{ account_data.account_number || "-" }}
                    </div>
                    <div class="item-v">
                      {{ account_data.bank_name || "-" }}
                    </div>
                    <div class="item-v">
                      {{ account_data.bank_code || "-" }}
                    </div>
                    <div class="item-v">
                      {{ account_data.branch_code || "-" }}
                    </div>
                    <div class="item-v">
                      {{ account_data.swift_code || "-" }}
                    </div>
                  </el-col>
                </el-row>
                <!-- <div class="item">
                    <div class="item-t">
                      {{ $t("Funds.Accounts.AccountName") }}
                    </div>
                    <div class="item-v">{{ account_data.account_name }}</div>
                </div>

                <div class="item">
                    <div class="item-t">
                      {{ $t("Funds.Accounts.AccountNumber") }}
                    </div>
                    <div class="item-v">{{ account_data.account_number }}</div>
                </div>
                <div class="item">
                    <div class="item-t">
                      {{ $t("Funds.Accounts.BankName") }}
                    </div>
                    <div class="item-v">{{ account_data.bank_name }}</div>
                </div>
                <div class="item">
                    <div class="item-t">
                      {{ $t("Funds.Accounts.BankCode") }}
                    </div>
                    <div class="item-v">{{ account_data.bank_code }}</div>
                </div>
                <div class="item">
                    <div class="item-t">
                      {{ $t("Funds.Accounts.BranchCode") }}
                    </div>
                    <div class="item-v">{{ account_data.branch_code }}</div>
                </div>
                <div class="item">
                    <div class="item-t">SWIFT Code</div>
                    <div class="item-v">{{ account_data.swift_code }}</div>
                </div> -->
                <!-- <div class="item">
                  <div>
                    <div class="item-t">{{ $t("Funds.Accounts.Status") }}</div>
                    <div
                      class="item-v"
                      :style="`color:${getStatus(account_data.status)}`"
                    >
                      {{ account_data.status }}
                    </div>
                  </div>
                  <div>
                    <i style="color: #00bea4" class="el-icon-document-copy"></i>
                  </div>
                </div> -->
              </div>

              <div class="item-box" style="text-align: center">
                <!-- <el-button
                  v-if="adDiag"
                  @click="addForm"
                  style="background: #00bea4; color: #fff"
                  >{{ $t("Funds.Accounts.AddFundAccount") }}</el-button
                > -->
                <button @click="addForm" v-if="adDiag" style="width:126px;" class="cur hv" type="button">{{ $t("Funds.Accounts.AddFundAccount") }}</button>
              </div>
              <div v-if="formDiag">
                <el-alert :title="$t('Funds.Accounts.FormTip')" type="warning" show-icon></el-alert>
                <div class="inp-box">
                  <div class="inp-lb">
                    {{ $t("Funds.Accounts.AccountName") }}
                  </div>
                  <CInput v-model="form.act_name" type="text" />
                </div>
                <div class="inp-box">
                  <div class="inp-lb">
                    {{ $t("Funds.Accounts.AccountNumber") }}
                  </div>
                  <CInput v-model="form.act_num" type="number" />
                </div>
                <div class="inp-box">
                  <div class="inp-lb">{{ $t("Funds.Accounts.BankName") }}</div>
                  <CInput v-model="form.act_bank" type="text" />
                </div>
                <!-- <div class="inp-box">
                  <div class="inp-lb">銀行代碼</div>
                  <CInput v-model="form.act_code" type="text" />
                </div> -->
                <!-- <div class="inp-box">
                  <div class="inp-lb">分行代碼</div>
                  <CInput v-model="form.act_fcode" type="text" />
                </div> -->
                <div class="inp-box">
                  <div class="inp-lb">
                    SWIFT Code {{ $t("Funds.Accounts.Optional") }}
                  </div>
                  <CInput v-model="form.act_swiftcode" type="text" />
                </div>
                <div class="inp-box">
                  <div class="inp-lb">
                    {{ $t("Funds.Accounts.BankAccountCertificate") }}
                  </div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    action="/request/user.php?type=add_fund_account"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :limit="1"
                    :file-list="fileList"
                    :auto-upload="false"
                    :on-change="handleChange"
                    accept=".jpg,.jpeg,.png,.gif,JPG,JPEG,PNG,GIF"
                  >
                    <el-button slot="trigger" size="small" type="primary">{{
                      $t("Funds.Accounts.SelectFile")
                    }}</el-button>
                    <!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button> -->
                    <div slot="tip" class="el-upload__tip">
                      {{ $t("Funds.Accounts.FileTip") }}
                    </div>
                  </el-upload>

                  <!-- <el-upload
                      class="upload-demo"
                      action="/request/user.php?type=add_fund_account"
                      :on-change="handleChange"
                      :limit="3"
                      :show-file-list="false"
                      :auto-upload="false"
                      :file-list="fileList"
                      accept=".jpg,.jpeg,.png,.gif,JPG,JPEG,PNG,GIF"
                    >
                      <el-button slot="trigger" size="small" type="primary"
                        >点击上传</el-button
                      >
                      <img v-if="form.act_credential" :src="form.act_credential.url" class="avatar">
                      <div slot="tip" class="el-upload__tip">
                        证明文件须与资金账户名一致，可为公司注册证书/身份证/护照/驾照，请确保图片文字清晰可见，文件格式为jpg/gif/png/jpeg/，大小不能超出5M
                      </div>
                    </el-upload> -->
                </div>
                <div style="text-align: right">
                  <!-- <el-button
                    @click="onSubmit"
                    style="background: #00bea4; color: #fff"
                    >{{ $t("Funds.Accounts.Submit") }}</el-button
                  > -->
                  <button style="width:70px" @click="onSubmit" class="btn custer sbtn" type="button">{{ $t("Funds.Accounts.Submit") }}</button>
                </div>
              </div>
              <!-- </div> -->
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFundAccount, addFundAccount, getPageNotice } from "../../api/http";
import TextScroll from "../../components/TextScroll.vue"
export default {
  components: {
    TextScroll
  },
  data() {
    return {
      account_data: "",
      fileList: [],
      //   file: "",
      form: {
        act_name: "",
        act_num: null,
        act_bank: "",
        act_code: "",
        act_fcode: "",
        act_swiftcode: "",
        act_credential: "",
      },
      //   formDate: '',
      formDiag: false,
      adDiag: false,
      fileImg: "",
      textArr: [
        // {
        //   id:1,
        //   val: '资金-账户'
        // }
      ],
      isVis: false
    };
  },
  watch: {
    formDiag(val) {
      if (val === true) {
        this.adDiag = false;
        return true;
      }
    },
  },
  created() {
    //   this.formDate = new FormData()
    this.getData();
  },
  methods: {
    // 请求通告数据
    getAnnouncement(noticeType) {
      let obj = {
        uid: window.localStorage.getItem("uid"),
        type: "get_page_notice",
        // page: "fund_fund"
        page: noticeType
      }
      getPageNotice(obj).then((res) => {
        console.log("notice==>", res)
        if(res.code == 200) {
          if(res.data.length !== 0) {
            this.isVis = true
            this.textArr = res.data
          }else {
            this.isVis = false
          }
        }
      })
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    getStatus(status) {
      return status === "Verifying"
        ? "#4CA1E5"
        : status === "Complete"
        ? "#00BEA4"
        : "#F56C6C";
    },
    addForm() {
      this.formDiag = true;
    },
    // 上传文件的回调
    handleChange(file, fileList) {
      console.log("file==>", file);
      console.log("filelist==>", fileList);
      // this.form.act_credential = file.raw
      const isLt5M = file.size / 1024 / 1024 < 5;
      let typeArr = ["image/jpg", "image/png", "image/gif", "image/jpeg"];
      let isJPG = typeArr.indexOf(file.raw.type);
      if (isJPG === -1) {
        this.$message.error(
          `${this.$t("Funds.Accounts.UploadTip1")}jpg/png/jpeg/gif`
        );
        fileList.pop();
      }
      if (!isLt5M) {
        this.$message.error(`${this.$t("Funds.Accounts.UploadTip2")} 5MB!`);
        fileList.pop();
      }
      this.getBase64(file.raw).then((res) => {
        const params = res;
        this.form.act_credential = params;
      });
      //   var that = this;
      //   var reader = new FileReader();
      //   reader.readAsDataURL(file.raw);
      //   console.log("file.raw", file.raw);
      //   reader.onload = function () {
      //     let d = reader.result.replace("/.*;base64,/, ''");
      //     console.log("dd==>", d);

      //     // that.form.act_credential = reader.result.replace("/.*;base64,/, ''")
      //   };
      // this.form.act_credential = file.url
      // this.form.act_credential = file.raw
      this.fileList = fileList;
    },
    // 添加资金账户提交
    onSubmit() {
      //   console.log(1);
      if (
        this.form.act_bank == "" ||
        this.form.act_num == null ||
        this.form.act_credential == ""
      ) {
        this.$message.warning("Validation is empty");
        return;
      }
      const formData = new FormData();
      //  formData.append("credential", this.form.act_credential)
      // formData.append("name", this.form.act_credential.name)
      // this.fileList.map(item => { //fileList本来就是数组，就不用转为真数组了
      //     formData.append("file", item.raw)  //将每一个文件图片都加进formdata
      //   })
      // formData.append("img", this.this.form.act_credential)
      console.log("formData=>", formData);
      //  return
      let data = {
        type: "add_fund_account",
        uid: window.localStorage.getItem("uid"),
        bank_name: this.form.act_bank,
        account_name: this.form.act_name,
        account_number: this.form.act_num,
        bank_code: this.form.act_code,
        branch_code: this.form.act_fcode,
        credential: this.form.act_credential,
        // credential: this.fileImg,
        // credential: formData,
        swift_code: this.form.act_swiftcode,
      };
      addFundAccount(data).then((res) => {
        if (res.code == 200) {
          this.$router.push("/funds/funds");
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取资金账户
    getData() {
      let data = {
        type: "get_fund_account",
        uid: window.localStorage.getItem("uid"),
      };
      getFundAccount(data).then((res) => {
        console.log("res==>", res);
        if (res.data.length !== 0) {
          this.account_data = res.data[0];
          this.adDiag = false;
        } else {
          this.adDiag = true;
        }
      });
    },
  },
};
</script>


<style scoped>
.sbtn:hover{
  background: rgba(0,190,164, .8) !important;
}
.sbtn:focus{
  border-color: #00BEA4;
  box-shadow: 0 0 0 .25rem rgba(0,190,164, .5) !important;
}
.custer{
  background: #00bea4;
  color: #fff; 
  width: 100%;
  outline:0;
}
/*  */
.cur{
  outline: none;
  border: none;
  color: #00BEA4;
  background: transparent;
  padding: 6px 12px;
  font-weight: bold;
}
.hv:hover{
  background: rgb(0,190,164);
  color: #fff;
  border-radius: 0.375rem;
}
/*  */
.in-item {
  display: flex;
  flex-direction: column;
}
.account-box {
  padding-left: 2.6rem;
  height: 100vh;
  background: #fff;
}
.act-card {
  width: 83%;
  margin: 0 auto;
  padding-top: 0.88rem;
}
.item {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}
.item-t {
  color: #3c2b2b;
  /* font-weight: bold; */
  padding-bottom: 1.25rem;
}
.item-v {
  color: #a196aa;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 1.25rem;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.inp-box {
  text-align: left;
}
.inp-lb {
  margin-bottom: 0.5rem;
}
.status {
  background: #4ca1e5;
  color: #fff;
  height: 2.5rem;
  line-height: 2.5rem;
  width: 100%;
}
</style>